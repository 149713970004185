<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox7"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('carbonBox7_input','碳排放输入', getZEdata)}}</template>
      <template slot="aside"></template>
      <a-form
        :form="form"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 11 }"
        @submit="handleSubmit"
      >
        <a-form-item :label="commonLangHandler('carbonBox7_timeFrame','时间范围', getZEdata)">
          <a-range-picker
          style="width: 160px;"
            v-model="yaerMode"
            :open="panelOpen"
            :placeholder="placeholderlist"
            format="YYYY-MM"
            :mode="mode2"
            @openChange="openChange"
            @panelChange="panelChangeOne"
          />
        </a-form-item>
        <a-form-item :label="commonLangHandler('carbonBox7_emissionSource','排放源', getZEdata)">
          <a-select v-model="searchObj['ITEM']" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
            <a-select-option
              v-for="(item, key) in ITEM1"
              :key="key"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="commonLangHandler('carbonBox7_solid','固体燃料', getZEdata)" v-if="solidIF">
          <a-select v-model="searchObj['solid']" @change="onsolidIF" :getPopupContainer="triggerNode => triggerNode.parentNode">
            <a-select-option
              v-for="(item, key) in solid"
              :key="key"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="commonLangHandler('carbonBox7_liquid','液体燃料', getZEdata)" v-if="liquidIF">
          <a-select v-model="searchObj['liquid']" @change="onliquidIF" :getPopupContainer="triggerNode => triggerNode.parentNode">
            <a-select-option
              v-for="(item, key) in liquid"
              :key="key"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="commonLangHandler('carbonBox7_gaseous','气体燃料', getZEdata)" v-if="GasIF">
          <a-select v-model="searchObj['Gas']" @change="onGasIF" :getPopupContainer="triggerNode => triggerNode.parentNode">
            <a-select-option
              v-for="(item, key) in Gas"
              :key="key"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :label="commonLangHandler('carbonBox7_used','使用量', getZEdata)">
          <a-input
            style="width: 80px"
            onkeyup="value=value.replace(/[^\d{1,}\.\d{1,}|\d{1,}]/g,'')"
            @change="ondischarge"
          />
          {{ TransformValue }}
        </a-form-item>
        <!-- <a-form-item label="凭证">
                <a-upload
                    name="avatar"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    :before-upload="beforeUpload"
                    @change="handleChange"
                >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                    <a-icon :type="loading ? 'loading' : 'plus'" />
                    <div class="ant-upload-text">
                        上传照片/PDF
                    </div>
                    </div>
                </a-upload>
                
            </a-form-item> -->
      </a-form>
      <div class="bottom-btns">
        <!-- <span>导入</span> -->
        <span @click="preservation" style="cursor: pointer; background: #3366ff"
          >{{commonLangHandler('carbonBox7_save','保存', getZEdata)}}</span
        >
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox7
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "carbonBox7",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    yaerMode(newValue, oldValue) {
      if (newValue.length == 0) {
        this.queryParam.releaseTimeStart = "";
        this.queryParam.releaseTimeEnd = "";
      }
    },
  },
  data() {
    return {
      panelOpen: false,
      placeholderlist: [this.LangHandler('start','开始'), this.LangHandler('end','结束')],
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),

      panelOpen: false,
      queryParam: {
        releaseTimeStart: "",
        releaseTimeEnd: "",
      },
      ITEM1: [
        { code: "工业过程排放", name: "工业过程排放" },
        { code: "热力", name: "热力" },
        { code: "固体燃料", name: "固体燃料" },
        { code: "液体燃料", name: "液体燃料" },
        { code: "气体燃料", name: "气体燃料" },
      ],
      mode2: ["month", "month"],
      yaerMode: [],
      loading: false,
      imageUrl: "",
      startTime: "",
      endTime: "",
      discharge: "",
      Usage: "",
      TransformValue: "",
      solid: [
        { code: "无烟煤", name: "无烟煤" },
        { code: "烟煤", name: "烟煤" },
        { code: "褐煤", name: "褐煤" },
        { code: "洗精煤", name: "洗精煤" },
        { code: "其他洗煤", name: "其他洗煤" },
        { code: "型煤", name: "型煤" },
        { code: "石油焦", name: "石油焦" },
        { code: "其他煤制品", name: "其他煤制品" },
        { code: "焦炭", name: "焦炭" },
      ],
      solidIF: false,
      liquid: [
        { code: "原油", name: "原油" },
        { code: "燃料油", name: "燃料油" },
        { code: "汽油", name: "汽油" },
        { code: "柴油", name: "柴油" },
        { code: "一般煤油", name: "一般煤油" },
        { code: "炼厂干气", name: "炼厂干气" },
        { code: "液化天然气", name: "液化天然气" },
        { code: "液化石油气", name: "液化石油气" },
        { code: "石脑油", name: "石脑油" },
        { code: "其他石油制品", name: "其他石油制品" },
      ],
      liquidIF: false,
      Gas: [
        { code: "天然气", name: "天然气" },
        { code: "焦炉煤气", name: "焦炉煤气" },
        { code: "高炉煤气", name: "高炉煤气" },
        { code: "转炉煤气", name: "转炉煤气" },
        { code: "其他煤气", name: "其他煤气" },
      ],
      GasIF: false,
      searchObj: {
        ITEM1: "",
        solid: "",
        liquid: "",
        Gas: "",
        VALUE: this.$moment(), //日期
        QUARTER: "0",
      },
      detailInfo: {},
    };
  },
  computed: {
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo;
    },
    appDictData() {
      return this.currentSelectMenuInfo.appDictData;
    },
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    openChange(status) {
      this.panelOpen = status;
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    ondischarge(e) {
      this.Usage = e.target.value;
      // //console.log(e.target.value);
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },

    // 获取时间戳
    panelChangeOne(value) {
      this.yaerMode = value;
      this.queryParam.releaseTimeStart = moment(value[0]).format("YYYY-MM");
      this.queryParam.releaseTimeEnd = moment(value[1]).format("YYYY-MM");
      this.yearShowOne = !this.yearShowOne;
      let Y = value[0]._d.getFullYear() + "-";
      let M =
        value[0]._d.getMonth() + 1 < 10
          ? "0" + (value[0]._d.getMonth() + 1)
          : value[0]._d.getMonth() + 1;
      let y = value[1]._d.getFullYear() + "-";
      let m =
        value[1]._d.getMonth() + 1 < 10
          ? "0" + (value[1]._d.getMonth() + 1)
          : value[1]._d.getMonth() + 1;
      this.startTime = Y + M;
      this.endTime = y + m;
      if (value[0] && value[0]) {
        this.panelOpen = false;
      }
      //console.log(this.startTime, this.endTime);
    },
    handleChange(value) {
      this.discharge = value;
      if (value == "工业过程排放") {
        this.solidIF = false;
        this.liquidIF = false;
        this.GasIF = false;
        this.TransformValue = "吨CO2";
      } else if (value == "热力") {
        this.solidIF = false;
        this.liquidIF = false;
        this.GasIF = false;
        this.TransformValue = "kWH";
      } else if (value == "固体燃料") {
        this.TransformValue = "t";
        this.solidIF = true;
        this.liquidIF = false;
        this.GasIF = false;
      } else if (value == "液体燃料") {
        this.TransformValue = "t";
        this.solidIF = false;
        this.liquidIF = true;
        this.GasIF = false;
      } else if (value == "气体燃料") {
        this.TransformValue = "10⁴Nm³";
        this.solidIF = false;
        this.liquidIF = false;
        this.GasIF = true;
      }
    },
    onsolidIF(value) {
      this.discharge = value;
    },
    onliquidIF(value) {
      this.discharge = value;
    },
    onGasIF(value) {
      this.discharge = value;
    },
    // 保存接口
    preservation() {
      let VM = this;
      const url =
        Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/add";
      let data = {
        starttime: this.startTime,
        endtime: this.endTime,
        emissionSource: this.discharge,
        consumption: this.Usage,
        deptcode: this.currentSelectDeptInfo.CODE,
        isConfirmed: "0",
      };
      this.$axios.post(url, data).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "成功！") {
          this.$message.success(res.data.msg);
        } else if (res.data.msg == "已有时间重叠的数据，确认是否插入") {
          VM.$messagebox
            .confirm("已有时间重叠的数据，确认是否插入", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              data.isConfirmed = "1";
              this.$axios.post(url, data).then((res) => {
                if (res.data.msg == "成功！") {
                  this.$message.success(res.data.msg);
                }
              });
            })
            .catch(() => {
              VM.$message({
                type: "info",
                message: "已取消导入数据",
              });
            });
        }
      });
    },
    //     handleChange(info) {
    //   if (info.file.status === 'uploading') {
    //     this.loading = true;
    //     return;
    //   }
    //   if (info.file.status === 'done') {
    //     // Get this url from response in real world.
    //     getBase64(info.file.originFileObj, imageUrl => {
    //       this.imageUrl = imageUrl;
    //       this.loading = false;
    //     });
    //   }
    // },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //console.log("Received values of form: ", values);
        }
      });
    },
    handleSelectChange(value) {
      //console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },

    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange() {
      this.getData();
    },
    //每月报警预警数
    getData() {
      // dataInfo: [],
      this.detailInfo = {};
    },
  },

  mounted() {
    let lang = sessionStorage.getItem("locale") || 'CH'
    if(lang=='EN'){
      this.ITEM1=[
        { code: "工业过程排放", name: "industrial emission" },
        { code: "热力", name: "Thermodynamics" },
        { code: "固体燃料", name: "solid fuel" },
        { code: "液体燃料", name: "liquid fuel" },
        { code: "气体燃料", name: "gaseous fuel" },
        ]
      }
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox7 {
  .bottom-btns {
    display: flex;
    justify-content: flex-end;
    span {
      width: 61px;
      height: 32px;
      box-sizing: border-box;
      background: #3366ff;
      border-radius: 4px;
      margin-left: 12px;
      line-height: 32px;
      text-align: center;

      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      &:nth-child(1) {
        background: #ff7300;
      }
    }
  }
  .card-content1 {
  }
}
</style>